@import '../variables.scss';

ul.account-list {
  list-style-type: none;
  padding: 0 1rem;
  text-align: left;

  .top-border {
    border-bottom: 1px solid $silver;
  }

  li {
    height: 65px;
    border-bottom: 1px solid $silver;

    .account-item {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 100%;
      font-size: 18px;
      font-weight: 400;
      font-family: $font-family;

      .avatar-container {
        margin-right: 0.5rem;
        width: 36px;
        height: 36px;
      }

      .arrow {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
}
