@import '../variables.scss';
$top-margin: 24px;

.external-providers {
  .provider-divider {
    margin-top: 4px;
    display: flex;
    align-items: center;

    hr {
      width: 100%;
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid $granite;
      margin: 20px 0;
    }
  }

  .external-login-button-group {
    .external-login-button {
      box-sizing: border-box;
      background: $white;
      border: 1px solid $granite;
      border-radius: 7px;
      height: 60px;
      width: 100%;

      &:not(:first-child) {
        margin-top: $top-margin;
      }

      i {
        &.provider-icon {
          width: 18px;
          height: 18px;
          position: relative;
          top: 2px;
        }
        &.icon {
          background-repeat: no-repeat;
          &-google {
            background-image: url('../assets/Logo-Google.svg');
          }

          &-microsoft {
            background-image: url('../assets/Logo-Microsoft.svg');
          }

          &-apple {
            background-image: url('../assets/Logo-Apple.svg');
            width: 26px;
            height: 26px;
            top: 6px;
          }
        }
      }

      span {
        &.buttonText {
          margin-left: 12px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: $onyx;
          position: relative;
          bottom: 1px;

          &-apple {
            top: -5px;
            right: 9px;
          }
        }
      }
    }
  }
}
