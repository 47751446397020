@import '../variables.scss';
$top-margin: 12px;

.signup-survey {
  display: flex;
  justify-content: center;

  .content {
    .logo {
      background-image: url('../assets/Logo.svg');
      background-repeat: no-repeat;
      height: 56px;
      width: 230px;
      background-size: 100% 100%;
      margin: auto;
      cursor: pointer;
    }

    .card {
      border-radius: 15px;
      background-color: white;
      box-shadow: 0 6px 12px rgb(0 0 0 / 23%), 0 10px 40px rgb(0 0 0 / 19%);
      padding: 30px;
      padding-top: 20px;
      margin-top: 30px;
      text-align: center;

      .header {
        font-family: $font-family;
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0em;
      }

      .error-state {
        color: $rusty-red;
        margin-bottom: 15px 0;
        font-size: 16px;
      }

      .survey {
        .row {
          display: block;
          margin-top: 3px;

          .validationContainer {
            display: block;

            .hidden {
              visibility: hidden;
            }

            .validationText {
              margin-left: auto;
              font-size: 13px;
              color: #d24d57;
              height: 21px;
              text-align: right;
            }

            select.validationInput {
              border-radius: 0px;
              -webkit-appearance: none;
              -moz-appearance: none;
              background: #e8ebec url(../assets/Icon-Caret-Down.svg) 95% 50% no-repeat;
              padding-right: 26px;
              option {
                color: #333;
              }
            }

            .validationInputInvalid {
              border: 1px solid #d24d57 !important;
            }

            .ui.input {
              width: 100%;
              height: 38px;
              input {
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                @-webkit-keyframes autofill {
                  100% {
                    font-size: 18px;
                  }
                }
                @keyframes autofill {
                  100% {
                    font-size: 18px;
                  }
                }
              }
            }
          }

          &.col2 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            .validationContainer {
              display: inline-block;
              width: 48%;
            }
          }

          &.promotion {
            margin-top: $top-margin;
            a {
              color: $brand-blue;
              cursor: pointer;
            }
          }
        }
      }

      .get-started {
        margin-top: $top-margin;

        .grecaptchav2-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          div { 
            margin-bottom: 10px;
          }
        }
        
        .grecaptchav3-container { 
          display: none;
        }


        .ui.button {
          background-color: $sunglow;
          width: 100%;
          height: 60px;
          color: $onyx;
          font-size: 22px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
        }
      }

      .terms-of-service {
        margin-top: $top-margin;
        color: $onyx;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;

        .terms-text {
          color: $onyx;
          text-decoration: underline;
        }
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}


