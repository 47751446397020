@import '../variables.scss';

.email-input {
  .validationText {
    margin-left: auto;
    font-size: 13px;
    color: #d24d57;
    height: 21px;
    text-align: right;
  }
  .validationInput {
    padding: 0;
  }

  .ui.input {
    width: 100%;
    height: 38px;
    input {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      padding-left: 15px;
    }

    i.icon {
      height: 20px;
      width: 20px;
      margin-right: 7px;
      opacity: 1;
      top: 8px;
      background-repeat: no-repeat;

      &.success {
        background-image: url('../assets/GreenCheckmark.svg');
        height: 24px;
        width: 24px;
        top: 5px;
      }

      &.invalid {
        background-image: url('../assets/InvalidIcon.svg');
        top: 8px;
      }

      &.warningcheck {
        background-image: url('../assets/Warning.svg');
        top: 9px;
      }
    }
  }
}
