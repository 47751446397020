@import '../variables.scss';

.error {
  font-family: $font-family;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .background {
    background-image: $brand-blue;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: unset;
    height: unset;
  }

  .error-wrap {
    height: 100vh;
    text-align: center;
  }

  .logo-wrap {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    min-height: 25%;

    .logo {
      background-image: url('../assets/Logo.svg');
      background-repeat: no-repeat;
      height: 56px;
      width: 230px;
      margin: auto;
    }
  }

  .error-header {
    text-align: center;
    font-weight: 400;
    color: black;
    padding-top: 15px;
    font-size: 35px;
    line-height: 41px;
    padding-bottom: 10px;
    &.success {
      padding-bottom: 10px;
    }
  }

  .form-card {
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
    display: block;
    text-align: center;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
    line-height: 1.45;
    padding: 1.5rem 2rem 2rem;
  }

  @media only screen and (max-width: $mobile-cutoff) {
    .form-card {
      width: auto;
      height: auto;
    }
  }

  .error-box-wrapper {
    background-color: $rusty-red-40;
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

  .error-subtext {
    font-size: 21px;
    font-weight: 500;
    display: inline-block;
    color: #000000;
    font-size: 16px;
  }

  .error-message-text {
    padding-left: 7px;
  }

  .requestid {
    padding-top: 15px;
  }
}
