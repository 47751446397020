@import '../variables.scss';

.account-selection {
  display: flex;
  justify-content: center;
  text-align: center;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: start;

    .header {
      margin: auto;
      font-family: 'Inter';
      font-weight: 400;
      font-size: 18px;
      line-height: 26.63px;
      color: $white;

      .error-state {
        color: $rusty-red;
        font-size: 16px;
        padding-bottom: 8px;
      }
    }

    @media only screen and (min-width: 568px) {
      .header {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
    }

    .main-content {
      background-color: $white;
      min-height: 90px;
      box-shadow: 0 6px 12px rgb(0 0 0 / 23%), 0 10px 40px rgb(0 0 0 / 19%);
      max-height: 580px;
      overflow: auto;

      ul {
        margin: 14px 0;
      }
    }

    footer {
      display: flex;
      flex-direction: column;

      a {
        margin: auto;
        font-family: 'Inter';
        font-weight: 400;
        font-size: 16px;
        line-height: 26.63px;
        color: $white;
      }

      .profile-link {
        margin-top: 2rem;
      }

      .logout-link {
        margin-top: 1.15rem;
        cursor: pointer;
      }
    }
  }
}
