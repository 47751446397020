@import '../variables.scss';
$top-margin: 24px;

.login {
  display: flex;
  justify-content: center;

  .content {
    .logo {
      background-image: url('../assets/Logo.svg');
      background-repeat: no-repeat;
      height: 56px;
      width: 230px;
      background-size: 100% 100%;
      margin: auto;
      cursor: pointer;
    }

    .open-mobile-app-logo {
      cursor: unset;
    }

    .card {
      border-radius: 15px;
      background-color: white;
      box-shadow: 0 6px 12px rgb(0 0 0 / 23%), 0 10px 40px rgb(0 0 0 / 19%);
      padding: 30px;
      padding-top: 20px;
      margin-top: 30px;
      text-align: center;

      .header {
        font-family: $font-family;
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0em;

        .company-name {
          font-size: 22px;
        }
      }

      .error-state {
        color: $rusty-red;
        margin-bottom: 15px 0;
        font-size: 16px;
      }

      .goformz-provider {
        margin-top: 14px;

        .ui.input {
          width: 100%;
          height: 38px;

          input {
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            padding-left: 15px;

            @-webkit-keyframes autofill {
              100% {
                font-size: 18px;
              }
            }

            @keyframes autofill {
              100% {
                font-size: 18px;
              }
            }
          }
        }

        .password-input {
          margin-top: $top-margin;
        }

        .password-controls {
          display: flex;
          margin-top: 5px;
          justify-content: space-between;

          .forgot-password {
            a {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: $granite;
            }
          }

          .show-password {
            label {
              color: $granite;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }

      .login-button {
        margin-top: $top-margin;

        .ui.button {
          background-color: $sunglow;
          width: 100%;
          height: 60px;
          color: $onyx;
          font-size: 22px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
        }
      }
    }
  }

  .open-mobile-app {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-top: unset;

    .goformz-logo-wrapper {
      background-color: #0b99c2;
      height: 90px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .goformz-logo {
      background-image: url('../assets/Logo.svg');
      background-repeat: no-repeat;
      height: 56px;
      width: 230px;
    }

    .display {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        margin-top: 150px;
        margin-bottom: 40px;
        font-size: 42px;
        font-weight: 900;
        line-height: 1.2143;
        text-align: center;
      }

      .mobile-logo {
        background-image: url('../assets/Open-Mobile-App-Image.jfif');
        background-repeat: no-repeat;
        height: 56px;
        width: 64px;
        background-size: 100% 100%;
      }

      .text {
        font-size: 18px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;
      }
    }
  }

  .login-footer {
    margin: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    a {
      color: $white;
      cursor: pointer;
    }

    &.sso-footer {
      margin-top: 29px;
    }

    &.signup-footer {
      margin-top: 16px;
    }
  }
}

.ui.modal {
  .close { 
    top: 0.1rem;
    right: -0.1rem;
    padding: none;
  }
  .mobile-environment-modal-body { 
    .environment-button { 
       margin-bottom: 5px;
       a { 
         width: 100%;
       }
    } 
   }

}
