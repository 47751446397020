@import './variables.scss';

.password-input {
  position: relative;

  .validationInput.input {
    padding-right: 25px !important;
  }
  .password-requirement-text {
    font-family: $font-family;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 15px;
    text-align: center;
    .valid {
      color: green;
    }
    .invalid {
      color: #d3555c;
    }
  }
  .confirm-password {
    margin-top: 10px;
  }
  .show-password-button {
    position: absolute;
    right: 0;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
}
