@import '../variables.scss';

.forgotpassword {
  font-family: $font-family;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .forgotpassword-text {
    text-align: center;
    margin-bottom: 35px;
    font-weight: 400;
    color: #333333;
    padding-top: 40px;
    font-size: 31px;
    line-height: 37px;
    font-family: $font-family;
    &.success {
      padding-bottom: 10px;
    }
  }

  .error-state {
    width: 380px;
    margin: 0 auto;
    background: #ecb7bd;
    padding: 5px;
    text-align: left;
  }

  .check-email-text {
    font-size: 21px;
    font-weight: 300;
    display: inline-block;
  }

  .form-group {
    margin-bottom: 15px;

    .form-control {
      background: #e8ebec;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 0;
      color: #333;
      font-size: 18px;
      height: 40px;
      -webkit-appearance: none;
      width: 380px;
      margin: auto;
      font-weight: 300;
      letter-spacing: 0.15px;
      padding: 6px 12px;
      @-webkit-keyframes autofill {
        100% {
          font-size: 18px;
        }
      }
      @keyframes autofill {
        100% {
          font-size: 18px;
        }
      }
    }
  }

  .forgotpassword-footer {
    z-index: 2;
    .forgotpassword-footer-item {
      a:hover {
        background-color: rgba(0, 0, 0, 0.15);
      }

      .forgotpassword-footer-text {
        color: #fff;
        text-decoration: none;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  @media only screen and (max-width: $mobile-cutoff) {
    .forgotpassword-footer {
      margin-top: 30px;
    }
    .primary-button {
      width: 100%;
    }
  }
  @media only screen and (max-width: 350px) {
    .form-card {
      width: 300px !important;
      padding: 1rem 1.2rem 2rem;
    }
    .form-control {
      width: 270px !important;
    }
    .forgot-form {
      margin: auto;
      width: 270px;
    }
    .primary-button {
      width: 270px;
    }
  }
}
