@import '../variables.scss';

.sso {
  font-family: $font-family;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sso-form {
    margin: auto;
    width: 380px;
  }

  @media only screen and (max-width: $mobile-cutoff) {
    .sso-form {
      width: 100%;
      .primary-button {
        width: 100%;
      }
    }
    .sso-footer-text {
      display: none;
    }
  }

  .form-card {
    .sso-text {
      text-align: center;
      font-weight: 400;
      color: #333333;
      padding-top: 10px;
      font-size: 27px;
      line-height: 41px;
    }

    .sso-subtext {
      padding-bottom: 25px;
      font-size: 16px;
      font-family: $font-family;
      font-weight: 300;
    }

    .error-state {
      width: 380px;
      margin: 0 auto;
      background: #ecb7bd;
      padding: 5px;
      text-align: left;
      margin-bottom: 15px;
    }

    .requestid {
      padding-top: 15px;
    }
  }

  .form-group {
    margin-bottom: 15px;

    .form-control {
      background: #e8ebec;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 0;
      color: #333;
      font-size: 18px;
      height: 40px;
      width: 100%;
      -webkit-appearance: none;
      margin: auto;
      font-weight: 300;
      letter-spacing: 0.15px;
      padding: 6px 12px;
    }
  }

  .sso-footer {
    z-index: 2;
  }

  .sso-footer-item {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .sso-footer-item a:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }

  .sso-footer-text {
    font-weight: 300;
    font-size: 16px !important;
    padding-left: 2px;
    padding-right: 2px;
    font-style: normal;
    font-size: 1em;
    line-height: 1.45;
    text-decoration: none;
    height: 22px;
    width: 228px;
    color: #ffffff;
    line-height: 22px;
    text-align: center;
  }

  @media only screen and (max-width: 350px) {
    .form-card {
      width: 300px !important;
      height: 350px;
      padding: 1rem 1.2rem 2rem;
    }
    .form-control {
      width: 270px !important;
    }
    .forgot-form {
      margin: auto;
      width: 270px;
    }
    .primary-button {
      width: 270px;
    }
  }
}
