/* Colors */
$fresh-air: #eefafe;
$sky-blue: #d9edf3;
$brand-blue: #0b99c2;
$slate: #455a64;

$onyx: #333;
$charcoal: #636363;
$granite: #838c90;
$pewter-blue: #7b99a1;
$light-slate: #b6c1c6;
$silver: #e8ebec;
$platinum: #f3f3f3;
$white-smoke: #f9f9f9;

$rusty-red: #d24d57;
$atomic-tangerine: #ff7a47;
$sunglow: #f7da61;
$caribbean-green: #29d6c7;

$rusty-red-40: rgba($rusty-red, 0.4);
$atomic-tangerine-40: rgba($atomic-tangerine, 0.4);
$sunglow-40: rgba($sunglow, 0.4);
$caribbean-green-40: rgba($caribbean-green, 0.4);

$white: #ffffff;

$red: $rusty-red;
$yellow: $sunglow;
$green: $caribbean-green;

$red: $rusty-red;
$orange: #fc7950;
$blue: #2b9abf;
$dark-blue: $brand-blue;
$light-blue: $sky-blue;
$light-blue-hover: #c5e4ee;
$pale-blue: $fresh-air;
$blue-grey: $pewter-blue;
$primary-orange: $atomic-tangerine;
$primary-orange-hover: $atomic-tangerine-40;

$grey: $charcoal;
$greyish: $granite;
$light-grey: $silver;
$medium-grey: $light-slate;
$dark-grey: $granite;
$black: $onyx;

$small-shadow: rgba(0, 0, 0, 0.1);
$big-shadow: rgba(0, 0, 0, 0.2);
$backgroundColorTransition: 150ms;
$tileSizeTransition: 200ms;

$off-white: $platinum;
$row-border-color: $platinum;

$ocean-blue: #065b74;

/* Dimensions */
$filter-panel-width: 440px;
$mobile-cutoff: 567px;

/* font */
$font-family: 'Nunito Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;

$text-link: #2b9abf;

$app-height: 867px;
$list-height: 713px;

$font-light: 200;
$font-book: 200;
$font-roman: 400;
$font-medium: 500;
$font-heavy: 800;
$font-black: 900;

$background-pattern-void: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAMElEQVQYV2P8+fPnfwYk8OvXLwZGZEGQABsbG0IQJgDSBFaJLAAW/Pz583+QFmQAAHyjH4aBwNBOAAAAAElFTkSuQmCC';
