@import '../variables.scss';

.sso-connect {
  display: flex;
  justify-content: center;

  .content {
    .logo {
      background-image: url('../assets/Logo.svg');
      background-repeat: no-repeat;
      height: 56px;
      width: 230px;
      background-size: 100% 100%;
      margin: auto;
      cursor: pointer;
    }

    .card {
      border-radius: 15px;
      background-color: white;
      box-shadow: 0 6px 12px rgb(0 0 0 / 23%), 0 10px 40px rgb(0 0 0 / 19%);
      padding: 35px 32px;
      margin-top: 30px;
      text-align: center;
      color: $onyx;

      .header {
        font-family: $font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 44px;
      }

      .error-state {
        color: $rusty-red;
        margin-bottom: 15px 0;
        font-size: 16px;
      }

      .sso-explanation {
        font-family: $font-family;
        font-style: normal;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: -0.017em;
        padding: 0 3px;

        .found-text {
          font-weight: 400;
          margin-top: 35px;
        }

        .provider-details {
          margin-top: 55px;
          font-weight: 700;
          .email-display {
          }
          .provider-display {
            margin-top: 20px;

            i.provider-icon {
              width: 18px;
              height: 18px;
              position: relative;
              top: 3px;
            }
            i.icon-google {
              content: url('../assets/Logo-Google.svg');
            }

            i.icon-microsoft {
              content: url('../assets/Logo-Microsoft.svg');
            }

            i.icon-apple {
              content: url('../assets/Logo-Apple.svg');
              width: 26px;
              height: 26px;
              top: 9px;
            }
            span {
              margin-left: 16px;
            }
          }
        }

        .connect-details {
          margin-top: 45px;
        }
      }

      .connect-action {
        margin-top: 45px;
        .ui.button {
          background-color: $sunglow;
          width: 100%;
          height: 60px;
          color: $onyx;
          font-size: 22px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
        }
      }
    }
  }

  .deny-footer-link {
    margin: auto;
    margin-top: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 21px;
    a {
      color: $white;
    }
  }
}
