@import './variables.scss';

.primary-button {
  background: $sunglow !important;
  color: $onyx;
  width: 380px;
  height: 59px;
  margin: 0.5rem 0;
  color: rgba(0, 0, 0, 0.7);
  font-size: 21px !important;
  line-height: 29px;
  text-align: center;
  font-weight: 700;
  border: none;
  margin-top: 10px !important;

  &.disabled {
    opacity: 0.65;
  }
}

.card-wrapper {
  z-index: 2;
}

.logo-wrap-large {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 25%;
  margin-top: 40px;
  margin-bottom: 40px;
  z-index: 2;

  .logo {
    background-image: url('./assets/Logo.svg');
    background-repeat: no-repeat;
    height: 56px;
    width: 230px;
    margin: auto;
  }
}

input,
button,
select,
optgroup,
textarea {
  font-family: $font-family !important;
}

div[id^='__lpform_'] {
  display: none !important;
}

.ui.input > input {
  background-color: $silver !important;
  color: $onyx;
  border: none;
  padding: 0px;
}

.content {
  display: flex;
  flex-direction: column;
  padding-top: 27px;
  padding-bottom: 36px;
  width: 470px;
  justify-content: center;
  z-index: 2;

  .form-secondary {
    margin-top: 20px;
    .mobile-action-buttons {
      display: none;
      a {
        margin: auto;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.5);
      }
      .mobile {
        margin-top: 7px;
      }
    }
  }
}

.form-card {
  border-radius: 15px;
  background-color: $white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
  display: block;
  text-align: center;
  font-weight: 400;
  font-style: normal;
  font-size: 1em;
  line-height: 1.45;
  width: 470px;
  padding: 1.3rem 2rem 2rem;
  z-index: 2;

  .action-button {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    text-decoration: none;
    height: 22px;
    line-height: 22px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.15px;
    border: none;
  }

  .form-secondary a:hover {
    color: $brand-blue;
  }
}

@media only screen and (max-width: $mobile-cutoff) {
  .background {
    width: auto;
    height: auto;
  }

  @media only screen and (max-width: 350px) {
    .form-card {
      width: 300px;
      height: 380px;
    }
  }
}

@media only screen and (max-width: $mobile-cutoff) {
  .error-state {
    width: auto !important;
    overflow-wrap: break-word;
  }
}

@media only screen and (max-width: $mobile-cutoff) {
  .content {
    width: 100% !important;
    z-index: 2;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    .logo {
      height: 32px !important;
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }

    .form-card {
      width: 100%;
      box-shadow: none;

      .form-control {
        width: 100%;
      }

      .mobile-action-buttons {
        display: flex;
        flex-direction: column;
      }
    }

    .card {
      margin-top: 0px !important;
      padding: 0px;
      border: none;
      box-shadow: none !important;

      .form {
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .form-secondary {
      .mobile-action-buttons {
        display: flex;
        flex-direction: column;
      }
    }

    .top-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      &.header {
        background-color: $brand-blue;
        height: 70px;

        .logo {
          background-image: url('./assets/Logo.svg');
          background-repeat: no-repeat;
          height: 32px;
          background-size: 100% 100%;
          width: 230px;
          margin: auto;
        }
      }

      .header-text {
        display: none;
      }
    }

    .logo-wrap-large {
      display: none;
    }
  }

  .login-footer {
    display: none;
  }
  .signup-footer-link {
    display: none;
    padding-bottom: 10px;
    &.mobile {
      display: block !important;
      a {
        color: #0366d6;
      }
    }
  }

  .header-text {
    font-size: 20px;
    width: 275px !important;
    padding-right: 10px;
  }
  .top-row.name {
    display: flex;
    flex-direction: column;
    .split-field {
      width: auto;
    }
  }
  .validationInput {
    height: 35px;
  }

  .addPromoCodeButton {
    height: 40px;
  }
}

.validationInput {
  width: 100%;
  background: $silver;
  box-shadow: none;
  color: $onyx;
  font-size: 18px;
  padding: 6px 12px;
  border: 1px solid $silver !important;
  box-shadow: none;

  height: 38px;
  &.disabled {
    color: #c0c0c0 !important;
  }
  cursor: text;
  &.select {
    cursor: pointer;
  }
  &.input {
    font-size: 18px;
  }
}
