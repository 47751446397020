@import './variables.scss';

.background {
  background-color: $brand-blue;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
