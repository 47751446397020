@import '../variables.scss';

.account-avatar {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background: $ocean-blue;

  svg {
    margin: 0 !important;

    div {
      color: #fff;
      font-size: 40px;
      text-align: center;
      line-height: 80px;
    }
  }
}
