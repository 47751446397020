.logout {
  .form-card {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
    display: block;
    text-align: center;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
    line-height: 1.45;
    padding: 1.5rem 2rem 2rem;

    .logout-text {
      text-align: center;
      font-weight: 400;
      color: #333333;
      padding-top: 10px;
      font-size: 27px;
      line-height: 41px;
    }
  }

  .confirmlogout-button {
    background: #f7da61;
    color: #333;
    width: 380px;
    height: 59px;
    margin: 0.5rem 0;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    font-weight: 700;
    border-radius: 3px;
    margin-top: 55px;
    border: none;
  }
}
