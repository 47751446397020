@import '../variables.scss';
$top-margin: 12px;

.signup {
  display: flex;
  justify-content: center;

  .content {
    .logo {
      background-image: url('../assets/Logo.svg');
      background-repeat: no-repeat;
      height: 56px;
      width: 230px;
      background-size: 100% 100%;
      margin: auto;
      cursor: pointer;
    }

    .card {
      border-radius: 15px;
      background-color: white;
      box-shadow: 0 6px 12px rgb(0 0 0 / 23%), 0 10px 40px rgb(0 0 0 / 19%);
      padding: 30px;
      padding-top: 20px;
      margin-top: 30px;
      text-align: center;

      .header {
        font-family: $font-family;
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0em;
      }

      .error-state {
        color: $rusty-red;
        margin-bottom: 15px 0;
        font-size: 16px;
      }

      .goformz-provider {
        margin-top: 15px;

        .validationText {
          margin-left: auto;
          font-size: 13px;
          color: #d24d57;
          height: 21px;
          text-align: right;
        }
        .validationInput {
          padding: 0;
        }

        .ui.input {
          width: 100%;
          height: 38px;
          input {
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            padding-left: 15px;
            width: 100%;
            @-webkit-keyframes autofill {
              100% {
                font-size: 18px;
              }
            }
            @keyframes autofill {
              100% {
                font-size: 18px;
              }
            }
          }
        }

        .password-input {
          margin-top: 24px;

          .password-requirement-text {
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0em;
            text-align: center;
            padding-top: 0;
            padding-bottom: 0;
            margin-top: $top-margin;
          }
        }
      }

      .get-started {
        margin-top: $top-margin;
        .ui.button {
          background-color: $sunglow;
          width: 100%;
          height: 60px;
          color: $onyx;
          font-size: 22px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
        }
      }

      .terms-of-service {
        margin-top: $top-margin;
        color: $onyx;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;

        .terms-text {
          color: $onyx;
          text-decoration: underline;
        }
      }
    }
  }

  .signup-footer-link {
    margin: auto;
    margin-top: $top-margin;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    &.mobile {
      display: none;
    }
    a {
      color: $white;
    }
  }
}
