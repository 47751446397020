@import '../variables.scss';

.resetpassword {
  font-family: $font-family;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: $mobile-cutoff) {
    .reset-form {
      width: 100%;
      .primary-button {
        width: 100%;
      }
    }
  }

  .form-card {
    &.success {
      height: 220px;
    }

    .resetpassword-text {
      text-align: center;
      font-weight: 400;
      color: #333333;
      padding-top: 20px;
      font-size: 32px;
      line-height: 41px;

      &.success {
        padding-bottom: 10px;
      }
    }

    .resetpassword-subtext {
      padding-bottom: 20px;
      font-size: 18px;
      font-family: $font-family;
      font-weight: 400;
    }

    .error-state {
      color: $rusty-red;
      margin-bottom: 15px 0;
      font-size: 16px;
    }
  }

  .form-group {
    margin-bottom: 15px;

    .form-control {
      background: #e8ebec;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 0;
      color: #333;
      font-size: 18px;
      height: 40px;
      -webkit-appearance: none;
      width: 380px;
      margin: auto;
      font-weight: 300;
      letter-spacing: 0.15px;
      margin-bottom: 15px;
      padding: 6px 12px;
      @-webkit-keyframes autofill {
        100% {
          font-size: 18px;
        }
      }
      @keyframes autofill {
        100% {
          font-size: 18px;
        }
      }
    }
  }

  .resetpassword-footer {
    z-index: 2;
    font-size: 16px;
    line-height: 21px;
    .resetpassword-footer-item {
      margin-top: 18px;
      padding-bottom: 25px;

      a:hover {
        background-color: rgba(0, 0, 0, 0.15);
      }

      .resetpassword-footer-text {
        color: #fff;
        text-decoration: none;
        font-weight: 300;
      }
    }
  }

  @media only screen and (max-width: 350px) {
    .form-card {
      width: 300px;
      padding: 1.3rem 1rem 1rem;
      height: 430px;
      z-index: 2;
    }
    .form-control {
      width: 270px !important;
    }
    .reset-form {
      margin: auto;
      width: 270px;
    }
    .primary-button {
      width: 270px;
    }
  }
}
