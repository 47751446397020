@import '../variables.scss';

.changepassword {
  font-family: $font-family;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .changepassword-title {
    text-align: center;
    margin-bottom: 15px;
    font-weight: 400;
    color: #333333;
    padding-top: 40px;
    font-size: 31px;
    line-height: 37px;
    font-family: $font-family;
    &.error {
      margin-bottom: 15px;
    }
  }

  .changepassword-subtext {
    padding-bottom: 10px;
    font-size: 18px;
    font-family: $font-family;
    font-weight: 400;
  }

  .error-state {
    width: 380px;
    margin: 0 auto;
    background: #ecb7bd;
    padding: 5px;
    text-align: left;
  }

  .form-control {
    background: #e8ebec;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    color: #333;
    font-size: 18px;
    height: 40px;
    -webkit-appearance: none;
    width: 380px;
    margin: auto;
    font-weight: 300;
    letter-spacing: 0.15px;
    margin-bottom: 15px;
    padding: 6px 12px;
  }

  .primary-button {
    margin: 0;
  }

  @media only screen and (max-width: $mobile-cutoff) {
    .reset-form {
      width: 100%;
      .primary-button {
        width: 100%;
      }
    }
  }
}
